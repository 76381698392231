import axios from 'axios';

export const setAuthToken = token => {
  if (token != null && token != '') {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('jwtToken', token);
  } else {
    delete axios.defaults.headers.common['Authorization'];
    localStorage.setItem('jwtToken', "");
  }
};
