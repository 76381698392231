import { atom } from 'jotai';

export const userAtom = atom({
  user: { username: '', id: '' },
  username: '',
  basic: '',
  session: '',
});

export const contentAtom = atom({
  articles: [],
  meta: {
    page: 1,
    take: 50,
    hasPreviousPage: false,
    hasNextPage: true
  }
});
