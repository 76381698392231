import React, { Fragment, Suspense, lazy } from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import theme from './theme';
import { Provider } from 'jotai';

import GlobalStyles from './GlobalStyles';
import Pace from './shared/components/Pace';

import { AuthProvider } from './AuthProvider';

const LoggedInComponent = lazy(() => import('./logged_in/components/Main'));

const LoggedOutComponent = lazy(() => import('./logged_out/components/Main'));

function App() {

  return (
    <Provider>
      <BrowserRouter>
        <AuthProvider />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <Pace color={theme.palette.primary.light} />
            <Suspense fallback={<Fragment />}>
              <Routes>
                <Route path="c/*" element={<LoggedInComponent />} />
                <Route index element={<LoggedOutComponent />} />
              </Routes>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
