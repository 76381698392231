
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useRef } from "react"
import { useAtom } from 'jotai';

import { setAuthToken } from './shared/functions/authToken';
import jwt_decode from 'jwt-decode';
import { userAtom } from './store';
import { differenceInMinutes } from 'date-fns';

export const AuthProvider = () => {
    const [state, setState] = useAtom(userAtom);
    const authCheckedAtRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.jwtToken;
        const isBaseRoute = location.pathname === "" || location.pathname === '/'
        if (token == null || token === "") {
            if (!isBaseRoute) {
                navigate('/');
            }
            return;
        }

        if (authCheckedAtRef != null && differenceInMinutes(Date.now(), authCheckedAtRef.current) <= 15) {
            return;
        }

        const decoded = jwt_decode(localStorage.jwtToken);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
            authCheckedAtRef.current = null;
            setAuthToken("");
            navigate('/');
            return;
        }

        setAuthToken(localStorage.jwtToken);

        if (decoded.username && state.username !== decoded.username) {
            setState({ ...state, username: decoded.username, basic: decoded.basic });
            authCheckedAtRef.current = Date.now();
        }

        if (isBaseRoute) {
            navigate('c/catalog');
        }
    }, [location])


    return null;
}